<template>
  <div class="auth-footer-btn d-flex justify-content-center">
    <!-- firebase google login -->
    <b-button
      block
      v-if="!useLaravel"
      @click="google_signin"
      lg
      variant="outline-dark"
      href="javascript:void(0)"
    >
      <feather-icon icon="" class="mr-50" />
      <span class="align-middle">Google</span>
    </b-button>

    <!-- laravel google login -->
    <b-button
      v-if="useLaravel"
      block
      lg
      variant="outline-dark"
      :href="google_url"
      target="_self"
    >
      <feather-icon icon="" class="mr-50" />
      <span class="align-middle">Google</span>
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import laravel from "@/libs/laravel";

export default {
  components: {
    BButton,
  },
  directives: {},
  data() {
    return {
      useLaravel: laravel.use,
      google_url: laravel.base_url + "/" + laravel.urls.google_login,
    };
  },
  methods: {
    google_signin() {
      if (laravel.use) {
        // does nothing
        // window.open(this.google_url);
      } else {
        // invoke auth util function
        googleLogin(this.$ability, this.$router, this.$toast);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>